<template>
    <div>
        <!-- Delete Modal -->
        <b-modal
            id="delete_confirmation_modal"
            title="Delete Member"
            size="sm"
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
        >
            <div>
                <b-row>
                    <b-col>
                        <h1>Are you sure?</h1>
                        <p>
                            You are about to remove '{{
                                delete_confirmation_modal.member.name
                            }}' from the organizing team.
                        </p>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col class="text-right">
                        <base-button
                            type="danger"
                            :pill="true"
                            class="my-4 btn-success"
                            :disabled="delete_confirmation_modal.loading"
                            @click="delete_confirmation_modal_delete()"
                            :loading="delete_confirmation_modal.loading"
                            :success="delete_confirmation_modal.success"
                            >Confirm
                        </base-button>
                        <base-button
                            type="success"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="$bvModal.hide('delete_confirmation_modal')"
                            :disabled="delete_confirmation_modal.loading"
                            >Close
                        </base-button>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
        <!-- C Modal -->
        <b-modal
            id="c_modal"
            title="Invite Organizing Team Member"
            size="md"
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
        >
            <div>
                <b-row>
                    <b-col>
                        <validation-observer
                            v-slot="{ handleSubmit }"
                            ref="formValidator"
                        >
                            <b-form
                                role="form"
                                @submit.prevent="
                                    handleSubmit(c_modal_invite_member)
                                "
                            >
                                <input
                                    type="submit"
                                    ref="c_modal_form_submit_button"
                                    hidden
                                />
                                <b-row>
                                    <b-col>
                                        <base-input
                                            :pill="true"
                                            label="Email Address"
                                            class="mb-3"
                                            name="Email Address"
                                            :rules="{
                                                required: true,
                                                email: true,
                                            }"
                                            placeholder="Email of the judge to be invited"
                                            v-model="c_modal.form.email"
                                            :disabled="c_modal.loading"
                                        >
                                        </base-input>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </validation-observer>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col class="text-right">
                        <base-button
                            type="danger"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="$bvModal.hide('c_modal')"
                            :disabled="c_modal.loading"
                            >Close
                        </base-button>
                        <base-button
                            type="success"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="$refs.c_modal_form_submit_button.click()"
                            :disabled="c_modal.loading"
                            :loading="c_modal.loading"
                            :success="c_modal.success"
                            >Invite
                        </base-button>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
        <b-card>
            <b-card-body>
                <b-row>
                    <b-col cols="6">
                        <h1>Hackathon Organizing Team</h1>
                    </b-col>
                    <b-col cols="6" class="text-right">
                        <el-tooltip
                            content="Add"
                            placement="top"
                            v-if="is_hackathon_creator"
                        >
                            <base-button
                                type="primary"
                                round
                                icon
                                size="sm"
                                @click="$bvModal.show('c_modal')"
                            >
                                <span class="btn-inner"
                                    ><i class="fa fa-plus"></i
                                ></span>
                            </base-button>
                        </el-tooltip>
                    </b-col>
                </b-row>

                <div v-if="$apollo.loading && table_data.length == 0">
                    <b-row
                        class="mt-2 d-flex align-items-center justify-content-center"
                    >
                        <b-col
                            class="d-flex align-items-center justify-content-center"
                        >
                            <Transition>
                                <h1>
                                    <i class="fas fa-spinner fa-spin ml-1"></i>
                                </h1>
                            </Transition>
                        </b-col>
                    </b-row>
                </div>
                <b-row v-else>
                    <b-col>
                        <el-table
                            class="table-responsive align-items-center table-flush no-lines"
                            header-row-class-name="thead-light"
                            row-class-name="table-rows"
                            :data="table_data"
                            :empty-text="`No organizing team members yet${
                                is_hackathon_creator
                                    ? ', use the \'+\' button to invite members.'
                                    : '...'
                            }`"
                        >
                            <el-table-column
                                label="Name"
                                min-width="240px"
                                sortable
                            >
                                <template v-slot="{ row }">
                                    <a :href="`mailto:${row.user.email}`" class="mb-0">{{ row.user.name }}</a>
                                </template>
                            </el-table-column>
                            <el-table-column label="" min-width="240px">
                                <template v-slot="{ row }">
                                    <a
                                        v-if="row.linkedin_url"
                                        :href="row.linkedin_url"
                                        target="_blank"
                                        >Linked In</a
                                    >
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="Role"
                                min-width="240px"
                                sortable
                                v-if="is_hackathon_creator"
                            >
                                <template v-slot="{ row }">
                                    <p class="mb-0" v-if="row.role == 'ADMIN'">
                                        Admin
                                    </p>
                                    <p class="mb-0" v-if="row.role == 'OWNER'">
                                        Owner
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column
                                min-width="180px"
                                align="center"
                                v-if="is_hackathon_creator"
                            >
                                <div slot-scope="{ row }" class="table-actions">
                                    <el-tooltip
                                        content="Remove From Organizing Team"
                                        placement="top"
                                    >
                                        <a
                                            href="#!"
                                            @click.prevent="
                                                delete_confirmation_modal_show(
                                                    row
                                                )
                                            "
                                            class="table-action"
                                            data-toggle="tooltip"
                                            data-original-title="Delete Member"
                                        >
                                            <i class="fas fa-trash"></i>
                                        </a>
                                    </el-tooltip>
                                </div>
                            </el-table-column>
                        </el-table>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
// Modules
import {
    Table,
    TableColumn,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    Tooltip,
} from "element-ui";

// Queries
import { GET_HACKATHON_MANAGEMENT_TEAM_MEMBERS } from "@/graphql/queries";

// Mutations
import {
    CREATE_HACKATHON_MANAGEMENT_TEAM_INVITE,
    UPDATE_HACKATHON_MANAGEMENT_TEAM_MEMBER,
    DELETE_HACKATHON_MANAGEMENT_TEAM_MEMBER,
} from "@/graphql/mutations";

// ORM

import {
    processHackathonRelayData,
    Hackathon,
    ManagementTeamMember,
} from "@/ORM/Hackathon/Hackathon.js";

export default {
    name: "HackthonCrudManagementTeamTable",
    components: {
        [Tooltip.name]: Tooltip,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
    },
    props: {
        hackathon_id: {
            type: String,
            description: "The Id of the hackathon being viewed.",
            default: null,
        },
    },
    apollo: {
        get_hackathon_management_team: {
            query: GET_HACKATHON_MANAGEMENT_TEAM_MEMBERS,
            result(res) {
                this.table_data = processHackathonRelayData(res, "allHackathonManagementTeamMember");
                this.hackathon = processHackathonRelayData(res, "hackathonHackathon");
                this.is_hackathon_creator =
                    res.data.hackathonHackathon.isCreator;
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    hackathon_id: this.hackathon_id,
                };
            },
            update(data) {
                this.apollo_data.get_hackathon_management_team = data;
            },
            skip: true,
        },
    },
    data() {
        return {
            apollo_data: {
                get_hackathon_management_team: null,
            },
            table_data: [],
            delete_confirmation_modal: {
                loading: false,
                success: false,
                form: {
                    active: false,
                },
                member: {
                    id: null,
                    name: null,
                },
            },
            c_modal: {
                loading: false,
                success: false,
                form: {
                    email: null,
                },
            },
            is_hackathon_creator: false,
            hackathon: new Hackathon(), 
        };
    },
    methods: {
        // Modals

        // delete_confirmation_modal
        delete_confirmation_modal_show(member) {
            this.delete_confirmation_modal.member = member;
            this.$bvModal.show("delete_confirmation_modal");
        },
        delete_confirmation_modal_delete() {
            if (this.delete_confirmation_modal.member.id) {
                this.delete_confirmation_modal.loading = true;

                this.$apollo
                    .mutate({
                        mutation: DELETE_HACKATHON_MANAGEMENT_TEAM_MEMBER,
                        variables: {
                            id: this.delete_confirmation_modal.member.id,
                        },
                    })
                    .then(() => {
                        this.$apollo.queries.get_hackathon_management_team.refetch();

                        this.delete_confirmation_modal.loading = false;

                        this.delete_confirmation_modal.success = true;
                        setTimeout(() => {
                            this.delete_confirmation_modal.success = false;
                        }, 1000);
                        setTimeout(() => {
                            this.$bvModal.hide("delete_confirmation_modal");
                        }, 800);
                        this.$notify({
                            message: `Successfully removed: '${this.delete_confirmation_modal.member.name}'' from the organizing team.`,
                            timeout: 5000,
                            icon: "ni ni-check-bold",
                            type: "success",
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        this.$notify({
                            message: `Could not remove: '${this.delete_confirmation_modal.member.name}' from the organizing team.`,
                            timeout: 5000,
                            icon: "ni ni-check-bold",
                            type: "success",
                        });
                        this.delete_confirmation_modal.loading = false;
                    });
            }
        },

        // c_modal
        c_modal_invite_member() {
            this.c_modal.loading = true;
            this.$apollo
                .mutate({
                    mutation: CREATE_HACKATHON_MANAGEMENT_TEAM_INVITE,
                    variables: {
                        hackathon_id: this.hackathon_id,
                        email: this.c_modal.form.email,
                    },
                })
                .then(() => {
                    this.c_modal.loading = false;

                    this.c_modal.success = true;
                    setTimeout(() => {
                        this.c_modal.success = false;
                    }, 1000);
                    setTimeout(() => {
                        this.$bvModal.hide("c_modal");
                    }, 800);
                    this.$notify({
                        message: `Invite successfully sent to: '${this.c_modal.form.email}'`,
                        timeout: 5000,
                        icon: "ni ni-check-bold",
                        type: "success",
                    });
                })
                .catch((err) => {
                    if (
                        String(err).includes("ID cannot represent value: None")
                    ) {
                        this.c_modal.success = true;
                        setTimeout(() => {
                            this.c_modal.success = false;
                        }, 1000);
                        setTimeout(() => {
                            this.$bvModal.hide("c_modal");
                        }, 800);
                        this.$notify({
                            message: `Invite successfully sent to: '${this.c_modal.form.email}'`,
                            timeout: 5000,
                            icon: "ni ni-check-bold",
                            type: "success",
                        });
                    } else {
                        console.log(err);
                    }

                    this.c_modal.loading = false;
                });
        },
    },
    watch: {
        hackathon_id() {
            if (this.hackathon_id) {
                graph_utils.enable_query(
                    this.$apollo.queries.get_hackathon_management_team
                );
            } else {
                graph_utils.disable_query(
                    this.$apollo.queries.get_hackathon_management_team
                );
            }
        },
    },
    mounted() {
        if (!this.$apollo.queries.get_hackathon_management_team.skip) {
            this.$apollo.queries.get_hackathon_management_team.refetch();
        }
    },
    created() {
        // setting this queries fetch policy
        graph_utils.set_fetch_cache_and_network(
            this.$apollo.queries.get_hackathon_management_team
        );
    },
};
</script>

<style></style>
